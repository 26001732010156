<template>
  <base-material-card
    color="primary"
    icon="mdi-database"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">
        Entidade alvo da atualização
      </div>
    </template>
    <v-form class="pt-5">
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <select-company
            :type="'outlined'"
            :selectedCompany="empresa"
            @companySelected="companySelected"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <select-all-bdgd-versions
            label="Versão da BDGD"
            type="outlined"
            :showIcon="false"
            :companyId="empresa.id"
            @bdgdVersionSelected="bdgdVersionSelected"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <select-bdgd-entity
            :type="'outlined'"
            @entitySelected="entitySelected"
          />
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
export default {
  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
    SelectAllBdgdVersions: () =>
      import('@/components/general/SelectAllBdgdVersions.vue'),
    SelectBdgdEntity: () => import('@/components/general/SelectBdgdEntity.vue')
  },
  props: {
    empresa: {
      type: Object,
      required: true
    },
    entidade: {
      type: String,
      required: true
    }
  },
  methods: {
    companySelected(newValue) {
      this.$emit('update:empresa', newValue);
    },
    bdgdVersionSelected(newValue) {
      this.$emit('update:bdgdVersao', newValue);
    },
    entitySelected(newValue) {
      this.$emit('update:entidade', newValue);
    }
  }
};
</script>
